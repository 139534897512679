export const listenToCspProgress = async ({
  fileId,
  fileStats,
  setStatusMessage,
  setLoading,

  progressPercentage
}) => {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_API_URI}/api/v1/csp-cobol-progress?file_id=${fileId}`
  );
  let completed = false;


  await new Promise((resolve, reject) => {
    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      console.log("Dados recebidos do SSE:", parsedData);
  
      if (parsedData.status === "processing") {
        const currentBlock = parsedData.current_block;
        const percentage = (currentBlock / fileStats.totalBlocks) * 100;
        console.log(
          `Bloco atual: ${currentBlock}, Porcentagem: ${percentage}%`
        );
        progressPercentage.current = { percentage };
        setStatusMessage(
          `Processando bloco ${currentBlock} de ${fileStats.totalBlocks}`
        );
      } else if (parsedData.status === "completed") {
        setStatusMessage("Conversão concluída!");
        console.log("Conversão concluída");
  
        eventSource.close();
        completed = true;
        resolve()
        setLoading(false);
      } else if (parsedData.status === "error") {
        setStatusMessage(`Erro: ${parsedData.message}`);
        console.error(`Erro recebido do SSE: ${parsedData.message}`);
        setLoading(false);
        eventSource.close();
        reject()
      }
    };

    eventSource.onerror = (error) => {
      console.error("Erro no SSE:", error);
      setStatusMessage("Erro ao conectar ao servidor.");
      setLoading(false);
      eventSource.close();
      completed = false;
      reject()
    };
  })

  return completed;
}

export const listenToBmsProgress = async ({
  fileId,
  fileStats,
  setStatusMessage,
  setLoading,

  progressPercentage
}) => {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_API_URI}/api/v1/mapa-bms-progress?file_id=${fileId}`
  );
  let completed = false;

  await new Promise((resolve, reject) => {
    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      console.log("Dados recebidos do SSE:", parsedData);
  
      if (parsedData.status === "processing") {
        const currentBlock = parsedData.current_block;
        const percentage = (currentBlock / fileStats.totalBlocks) * 100;
        console.log(
          `Bloco atual: ${currentBlock}, Porcentagem: ${percentage}%`
        );
        progressPercentage.current = { percentage };
        setStatusMessage(
          `Processando bloco ${currentBlock} de ${fileStats.totalBlocks}`
        );
      } else if (parsedData.status === "completed") {
        setStatusMessage("Conversão concluída!");
        console.log("Conversão concluída");
  
        eventSource.close();
        completed = true;
        resolve()
        setLoading(false);
      } else if (parsedData.status === "error") {
        setStatusMessage(`Erro: ${parsedData.message}`);
        console.error(`Erro recebido do SSE: ${parsedData.message}`);
        setLoading(false);
        eventSource.close();
        reject()
      }
    };

    eventSource.onerror = (error) => {
      console.error("Erro no SSE:", error);
      setStatusMessage("Erro ao conectar ao servidor.");
      setLoading(false);
      eventSource.close();
      completed = false;
      reject()
    };
  })

  return completed;
}

