// /insert-story/{id_projeto}

import axios from "axios";

export const createHistory = async (projectId, history) => {
  const response = await axios.post(
    `/api/v1/insert-story/${projectId}`,
    history
  );

  return response;
};
