import { Box, Typography } from "@mui/material";

export const DetailsBlock = ({ data }) => {
  if (!data.length) return null;

  return (
    <Box
      sx={{
        border: "solid 1px #ccc",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Box display="flex" flexDirection="column" gap="10px">
        {data?.map((item) => (
          <Box display="flex" gap="15px" alignItems="center">
            <Typography fontWeight={600} fontSize="18px">
              {item?.label}:
            </Typography>
            <Typography fontSize="18px">
              <em>{item?.content}</em>
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
