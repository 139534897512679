import axios from "axios";

export const updateHistory = async (projectId, historyId, historyData) => {
  if (!projectId || !historyId) {
    throw new Error("ID do projeto e ID da história são obrigatórios");
  }

  try {
    const response = await axios.put(
      `/api/v1/update-story/${projectId}/${historyId}`,
      historyData
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar história:", error);
    throw error;
  }
};
