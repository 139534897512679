import axios from "axios";

export const updateProject = async (projectId, projectData) => {
  if (!projectId) {
    throw new Error("ID do projeto é obrigatório");
  }

  try {
    const response = await axios.put(
      `/api/v1/update-project/${projectId}`,
      projectData
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar projeto:", error);
    throw error;
  }
};
