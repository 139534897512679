import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Container } from "../../components/Container";
import RequisitosBreadcrumb from "../../components/Requisitos/RequisitosBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailsBlock } from "../../components/DetailsBlock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDate } from "../../helpers/formatDate";
import { HistoriaForm } from "../../components/Requisitos/HistoriaForm";
import { useState, useEffect } from "react";
import axios from "axios";

// Nova função para formatar data e hora
const formatDateWithTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "America/Sao_Paulo",
  });
};

export const DetalhesDaHistoria = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHistoryFormOpen, setIsHistoryFormOpen] = useState(false);
  const [anexos, setAnexos] = useState([]);

  const handleChatWithHistory = () => {
    navigate("/alice-requisitos", {
      state: {
        redirectedFrom: "historyDetails",
        hasHistoryMemory: true,
        searchType: "chat",
        selectedHistory: location?.state?.selectedHistory,
        projectId: location?.state?.projectId,
      },
    });
  };

  useEffect(() => {
    const fetchAnexos = async () => {
      try {
        const response = await axios.get(
          `/api/v1/get-story/${location?.state?.projectId}/${location?.state?.selectedHistory?.id_historia}`
        );
        setAnexos(response.data.anexos || []);
      } catch (error) {
        console.error("Erro ao buscar anexos:", error);
      }
    };

    if (location?.state?.selectedHistory?.id_historia) {
      fetchAnexos();
    }
  }, [
    location?.state?.projectId,
    location?.state?.selectedHistory?.id_historia,
  ]);

  return (
    <Container>
      <RequisitosBreadcrumb
        currentPage={`História - ${location?.state?.selectedHistory?.titulo}`}
        onGoBack={() =>
          navigate(`/alice-requisitos/projeto/${location?.state?.projectId}`)
        }
      />
      <Box>
        <DetailsBlock
          data={[
            {
              label: "Título",
              content: location?.state?.selectedHistory?.titulo || "",
            },
            {
              label: "Descrição",
              content: location?.state?.selectedHistory?.descricao || "",
            },
            {
              label: "Status",
              content: location?.state?.selectedHistory?.status || "",
            },
            {
              label: "Prioridade",
              content: location?.state?.selectedHistory?.prioridade || "",
            },
            {
              label: "Responsável",
              content: location?.state?.selectedHistory?.responsavel || "",
            },
            {
              label: "Data de Criação",
              content: location?.state?.selectedHistory?.data_criacao
                ? formatDate(
                    new Date(location?.state?.selectedHistory?.data_criacao)
                  )
                : "",
            },
            {
              label: "Data de Conclusão Estimada",
              content: location?.state?.selectedHistory?.data_conclusao_estimada
                ? formatDate(
                    new Date(
                      location?.state?.selectedHistory?.data_conclusao_estimada
                    )
                  )
                : "",
            },
          ]}
        />
      </Box>

      {anexos.length > 0 && (
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Anexos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {anexos
                  .sort((a, b) => new Date(b.data_criacao) - new Date(a.data_criacao))
                  .map((anexo, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={anexo.nome_documento}
                        secondary={`Adicionado em: ${formatDateWithTime(
                          anexo.data_criacao
                        )} por ${anexo.username}`}
                      />
                      <Button
                        href={anexo.url_documento}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="outlined"
                      >
                        Visualizar
                      </Button>
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      <Box gap="15px" display="flex" justifyContent="center">
        <Button onClick={() => setIsHistoryFormOpen(true)} variant="outlined">
          Editar História
        </Button>
        <Button onClick={() => handleChatWithHistory()} variant="outlined">
          Chat com esta História
        </Button>
      </Box>

      {isHistoryFormOpen && (
        <HistoriaForm
          initialValues={location?.state?.selectedHistory}
          open={isHistoryFormOpen}
          historyId={location?.state?.selectedHistory?.id_historia}
          handleClose={() => {
            setIsHistoryFormOpen(false);
          }}
          isEdit={true}
        />
      )}
    </Container>
  );
};
