import { Box, CircularProgress } from "@mui/material";

export const FullPageLoading = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      minHeight="100%"
      minWidth="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 0, 0, 0.5)"
      sx={{
        color: "#000!important",
        zIndex: 9999,
      }}
    >
      <CircularProgress size={80} />
    </Box>
  );
};
