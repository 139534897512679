import HttpClient from "../../config/httpClient";

export const handleCspConversionResult = async ({
  fileId,
  setConversionResult,
  setStatusMessage,
  setLoading
}) => {
  HttpClient.get(`/api/v1/csp-cobol-result/${fileId}`)
    .then((resultResponse) => {
      if (resultResponse.ok) {
        return resultResponse.json();
      } else {
        setStatusMessage("Erro ao buscar o resultado da conversão.");
        console.error("Erro ao buscar o resultado da conversão");
      }
    })
    .then((resultData) => {
      if (resultData) {
        console.log("Resultado da conversão recebido:", resultData);
        setConversionResult(resultData.converted_code);
      }
    })
    .catch((error) => {
      console.error("Erro ao buscar o resultado da conversão:", error);
      setStatusMessage("Ocorreu um erro ao buscar o resultado da conversão.");
    }).finally(() => {
      setLoading(false);
    });
};

export const handleBmsConversionResult = async ({
  fileId,
  setConversionResult,
  setStatusMessage,
  setLoading
}) => {
  HttpClient.get(`/api/v1/mapa-bms-result/${fileId}`)
    .then((resultResponse) => {
      if (resultResponse.ok) {
        return resultResponse.json();
      } else {
        setStatusMessage("Erro ao buscar o resultado da conversão.");
        console.error("Erro ao buscar o resultado da conversão");
      }
    })
    .then((resultData) => {
      if (resultData) {
        console.log("Resultado da conversão recebido:", resultData);
        setConversionResult(resultData.converted_code);
      }
    })
    .catch((error) => {
      console.error("Erro ao buscar o resultado da conversão:", error);
      setStatusMessage("Ocorreu um erro ao buscar o resultado da conversão.");
    }).finally(() => {
      setLoading(false);
    });
};
