// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter', 
      '-apple-system', 
      'BlinkMacSystemFont', 
      '"Segoe UI"', 
      'Roboto', 
      '"Helvetica Neue"', 
      'Arial', 
      'sans-serif'
    ].join(','),
    fontSize: 16, // Tamanho de fonte padrão para melhor legibilidade
    body1: {
      fontFamily: [
        'Inter', 
        '-apple-system', 
        'BlinkMacSystemFont', 
        '"Segoe UI"', 
        'Roboto', 
        '"Helvetica Neue"', 
        'Arial', 
        'sans-serif'
      ].join(','),
      fontSize: 16,
      lineHeight: 1.5, // Linha de texto com espaçamento adequado para leitura
    },
    body2: {
      fontFamily: [
        'Inter', 
        '-apple-system', 
        'BlinkMacSystemFont', 
        '"Segoe UI"', 
        'Roboto', 
        '"Helvetica Neue"', 
        'Arial', 
        'sans-serif'
      ].join(','),
      fontSize: 14,
      lineHeight: 1.43,
    },
  },
});

export default theme;
