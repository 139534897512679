import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Paper,
  CircularProgress,
  Box,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
import UploadForm from "../components/UploadForm";
import ConversionOptions from "../components/ConversionOptions";
import COBOLResponse from "../components/COBOLResponse";
import {
  convertBms,
  convertCsp,
  handleBmsConversionResult,
  handleCspConversionResult,
  listenToBmsProgress,
  listenToCspProgress,
} from "../services/alice-cobol";

const AliceCOBOL = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conversionResult, setConversionResult] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [fileStats, setFileStats] = useState({ totalLines: 0, totalBlocks: 0 });
  const progressPercentage = useRef({ currentBlock: 0, percentage: 0 });
  const handleOptionSelect = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  const handleFileSelect = useCallback(async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const fileContent = await file.text();
    const lines = fileContent.split("\n").length;
    const blocks = Math.ceil(lines / 200);
    setFileStats({ totalLines: lines, totalBlocks: blocks });
  }, []);

  const handleCspConversion = useCallback(async () => {
    if (!selectedFile || !selectedOption) return;

    setLoading(true);
    setStatusMessage("Processando... Isso pode levar até 2 minutos.");

    try {
      const response = await convertCsp(selectedFile);

      if (response.ok) {
        const fileId = response.headers.get("X-File-Id");
        console.log(`File ID recebido: ${fileId}`);
        const completed = await listenToCspProgress({
          fileId,
          fileStats,
          setStatusMessage,
          setConversionResult,
          setLoading,
          progressPercentage,
        });

        if (!completed) return;
        await handleCspConversionResult({
          fileId,
          setConversionResult,
          setStatusMessage,
          setLoading,
        });
      } else {
        throw new Error("Erro na conversão");
      }
    } catch (error) {
      console.error("Erro na conversão:", error);
      setStatusMessage(
        "Ocorreu um erro durante a conversão. Por favor, tente novamente."
      );
      setLoading(false);
    }
  }, [selectedFile, selectedOption, fileStats]);

  const handleBmsConversion = useCallback(async () => {
    if (!selectedFile || !selectedOption) return;

    setLoading(true);
    setStatusMessage("Processando... Isso pode levar até 2 minutos.");

    try {
      const response = await convertBms(selectedFile);

      if (response.ok) {
        const fileId = response.headers.get("X-File-Id");
        console.log(`File ID recebido: ${fileId}`);
        const completed = await listenToBmsProgress({
          fileId,
          fileStats,
          setStatusMessage,
          setConversionResult,
          setLoading,
          progressPercentage,
        });

        if (!completed) return;
        await handleBmsConversionResult({
          fileId,
          setConversionResult,
          setStatusMessage,
          setLoading,
        });
      } else {
        throw new Error("Erro na conversão");
      }
    } catch (error) {
      console.error("Erro na conversão:", error);
      setStatusMessage(
        "Ocorreu um erro durante a conversão. Por favor, tente novamente."
      );
      setLoading(false);
    }
  }, [selectedFile, selectedOption, fileStats]);

  const handleDownload = useCallback(() => {
    const element = document.createElement("a");
    const file = new Blob([conversionResult], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = selectedFile.name.replace(".txt", "_convertido.txt");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    console.log("Arquivo de conversão baixado");
  }, [conversionResult, selectedFile]);

  const handleGoBack = useCallback(() => {
    setSelectedOption(null);
    setSelectedFile(null);
    setConversionResult("");
    setStatusMessage("");
    setFileStats({ totalLines: 0, totalBlocks: 0 });
    setLoading(false);
  }, []);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        {!selectedOption && (
          <ConversionOptions
            onOptionSelect={handleOptionSelect}
            onFileSelect={handleFileSelect}
          />
        )}
        {selectedOption && (
          <>
            {selectedOption !== "chat" && (
              <>
                <UploadForm
                  handleGoBack={handleGoBack}
                  onFileSelect={handleFileSelect}
                  selectedFile={selectedFile}
                />
                {selectedFile && selectedOption === 'csp' && (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">
                      Total de linhas: {fileStats.totalLines}
                    </Typography>
                    <Typography variant="body1">
                      Dividido em {fileStats.totalBlocks} blocos
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCspConversion}
                      style={{ marginTop: "1rem" }}
                    >
                      Converter arquivo para COBOL CICS
                    </Button>
                  </Box>
                )}
                {selectedFile && selectedOption === 'bms' && (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">
                      Total de linhas: {fileStats.totalLines}
                    </Typography>
                    <Typography variant="body1">
                      Dividido em {fileStats.totalBlocks} blocos
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBmsConversion}
                      style={{ marginTop: "1rem" }}
                    >
                      Converter arquivo de Mapa para BMS
                    </Button>
                  </Box>
                )}
              </>
            )}
            {loading && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mt={2}
              >
                <CircularProgress />
                <Typography variant="body1" style={{ marginTop: "1rem" }}>
                  {statusMessage}
                </Typography>
                <Box mt={2} width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={progressPercentage?.current?.percentage}
                  />
                  <Typography variant="h6" style={{ marginTop: '0.5rem', textAlign: 'center' }}>
                      {`${Math.round(progressPercentage?.current?.percentage)}% concluído`}
                  </Typography>
                </Box>
              </Box>
            )}
            {conversionResult && (
              <Box mt={2}>
                <COBOLResponse text={conversionResult} />
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                  >
                    Baixar Resultado
                  </Button>
                </Box>
              </Box>
            )}
            {selectedOption === "chat" && <COBOLResponse />}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default AliceCOBOL;
