export const HttpClient = {
  async post(url, body) {
    const headers = body instanceof FormData
      ? {} // FormData configura automaticamente o Content-Type
      : { "Content-Type": "application/json" };

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });

    return response;
  },

  async get(url) {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // Define que esperamos JSON
      },
    });

    return response;
  },
};

export default HttpClient;
