import React from "react";
import { Button, Box } from "@mui/material";

const ConversionOptions = ({ onOptionSelect, onFileSelect }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
      <Button variant="outlined" component="label">
        <input
          type="file"
          accept=".txt"
          hidden
          onChange={(event) => {
            onFileSelect(event).then(() => onOptionSelect("csp"));
          }}
        />
        Upload de arquivo CSP para converter em COBOL CICS
      </Button>
      <Button
        variant="outlined"
        component="label"
        style={{ marginTop: "1rem" }}
      >
        <input
          type="file"
          accept=".txt"
          hidden
          onChange={(event) => {
            onFileSelect(event).then(() => onOptionSelect("bms"));
          }}
        />
        Upload de arquivo de Mapa para converter em BMS
      </Button>
    </Box>
  );
};

export default ConversionOptions;
