import { Box, Button } from "@mui/material";
import { TabelaHistorias } from "./TabelaHistorias";
import { HistoriaForm } from "./HistoriaForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Historias = ({ histories, refreshHistories }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [historyToEdit, setHistoryToEdit] = useState(null);
  const [isHistoryFormOpen, setIsHistoryFormOpen] = useState(false);

  const handleChatWithHistory = (history) => {
    navigate("/alice-requisitos", {
      state: {
        searchType: "chat",
        hasHistoryMemory: true,
        selectedHistory: history,
        projectId: params?.id,
      },
    });
  };

  const handleViewHistoryDetails = (history) => {
    navigate(`/alice-requisitos/projeto/${params?.id}/detalhes-da-historia`, {
      state: {
        selectedHistory: history,
        projectId: params?.id,
      },
    });
  };

  const handleEditHistory = (history) => {
    setHistoryToEdit(history);
    setIsHistoryFormOpen(true);
  };

  const handleCloseHistoryForm = () => {
    setHistoryToEdit(null);
    setIsHistoryFormOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="15px"
      maxWidth="1200px"
      margin="0 auto"
    >
      {histories?.length > 0 && (
        <Box>
          <TabelaHistorias
            histories={histories}
            onEdit={handleEditHistory}
            onViewHistoryDetails={handleViewHistoryDetails}
            onChatWithHistory={handleChatWithHistory}
          />
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsHistoryFormOpen(true)}
        >
          Criar nova História
        </Button>
      </Box>

      {isHistoryFormOpen && (
        <HistoriaForm
          initialValues={historyToEdit || {}}
          open={isHistoryFormOpen}
          refreshHistories={refreshHistories}
          handleClose={handleCloseHistoryForm}
          isEdit={!!historyToEdit}
          projectId={params?.id}
          historyId={historyToEdit?.id_historia}
        />
      )}
    </Box>
  );
};
