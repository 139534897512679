import React from "react";
import {
  Container,
  TextField,
  Paper,
  List,
  ListItem,
  Avatar,
  CircularProgress,
  Typography,
  Button,
  Box,
  ListItemText,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as AliceIcon } from "../../assets/icons/AliceIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/UserIcon.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile"; // Ícone de anexo
import DeleteIcon from "@mui/icons-material/Delete"; // Ícone de remoção
import IAResponse from "../IAResponse"; // Importa o novo componente
import { useLocation } from "react-router-dom";

const ChatContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  padding: "1rem",
  width: "100%", // Alterado para 100% para ocupar toda a largura da tela
});

const MessageList = styled(List)({
  width: "100%", // Ocupa toda a largura disponível
  flexGrow: 1,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  padding: "0 1rem",
});

const MessageItem = styled(ListItem)(({ sender }) => ({
  backgroundColor: sender === "user" ? "#e0f7fa" : "#f1f1f1",
  borderRadius: "10px",
  marginBottom: "0.5rem",
  padding: "0.5rem",
  alignSelf: sender === "user" ? "flex-end" : "flex-start",
  display: "flex",
  alignItems: "center",
  maxWidth: "95%", // Aumenta a largura máxima do elemento para 95% da largura do contêiner
  wordBreak: "break-word",
  whiteSpace: "pre-wrap", // Garante que o texto respeite as quebras de linha
  margin: "0.2rem",
}));

const AvatarStyled = styled(Avatar)({
  marginRight: "1rem", // Adiciona um espaço à direita do ícone
  alignSelf: "center", // Centraliza verticalmente o avatar
});

const MessageText = styled(ListItemText)({
  alignSelf: "center", // Centraliza verticalmente o texto
  whiteSpace: "pre-wrap", // Garante que o texto respeite as quebras de linha
  wordBreak: "break-word",
});

const IAResponseContainer = styled(Box)({
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const InputContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "1rem",
  boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  flexShrink: 0,
});

const StyledButton = styled(Button)({
  minWidth: "120px", // Define uma largura mínima para os botões
  height: "36px", // Define uma altura fixa para os botões
  fontSize: "0.875rem", // Reduz o tamanho da fonte
  padding: "6px 16px", // Ajusta o padding interno
  marginLeft: "0.5rem", // Reduz a margem entre os botões
  lineHeight: "1.2", // Reduz o espaçamento entre as linhas
  "& .MuiButton-label": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2px", // Adiciona uma pequena margem no topo
    marginBottom: "2px", // Adiciona uma pequena margem na base
  },
});

export const ChatRequisitos = ({
  messages,
  loading,
  chatInput,
  setChatInput,
  handleSendMessage,
  handleFileChange,
  file,
  removeFile,
  handleGenerateDocument,
  messagesEndRef, // Adicione esta prop
}) => {
  const location = useLocation();

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <ChatContainer maxWidth="md">
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 72px)",
          width: "100%",
        }}
      >
        <MessageList>
          {messages.map((message, index) => (
            <MessageItem key={index} sender={message.sender}>
              <AvatarStyled>
                {message.sender === "user" ? (
                  <UserIcon width={24} height={24} />
                ) : (
                  <AliceIcon width={24} height={24} />
                )}
              </AvatarStyled>
              {message.sender === "user" ? (
                <MessageText primary={message.text} />
              ) : (
                <IAResponseContainer>
                  <IAResponse text={message.text} />
                </IAResponseContainer>
              )}
            </MessageItem>
          ))}
          {loading && (
            <MessageItem sender="bot">
              <AvatarStyled>
                <AliceIcon width={24} height={24} />
              </AvatarStyled>
              <Box display="flex" alignItems="center">
                <CircularProgress size={24} />{" "}
                {/* Indicador de carregamento animado */}
                <Typography variant="body1" style={{ marginLeft: "0.5rem" }}>
                  Pensando...
                </Typography>
              </Box>
            </MessageItem>
          )}
          <div ref={messagesEndRef} /> {/* Adicione esta linha */}
        </MessageList>

        {/* Área de Input */}
        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3} // Define a altura inicial
            placeholder="Digite sua mensagem... (Shift + Enter para nova linha)"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress} // Muda para handleKeyPress
          />
          <IconButton
            color="primary"
            component="label" // Atribui o ícone de anexo ao input do tipo file
            style={{ marginLeft: "1rem" }}
          >
            <AttachFileIcon />
            <input
              type="file"
              hidden
              accept=".txt,.doc,.docx,.pdf"
              onChange={handleFileChange} // Lida com a seleção de arquivo
            />
          </IconButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            disabled={chatInput.trim() === ""}
          >
            Enviar
          </StyledButton>
          {location?.state?.hasHistoryMemory && (
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={handleGenerateDocument}
            >
              Gerar
              <br />
              Documento
            </StyledButton>
          )}
        </InputContainer>

        {/* Exibição do arquivo anexado */}
        {file && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">{file.name}</Typography>
            <IconButton onClick={removeFile}>
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        )}
      </Paper>
    </ChatContainer>
  );
};

export default ChatRequisitos;
